import { withDependencies } from '@wix/thunderbolt-ioc'
import { IStructureAPI, StructureAPI, Props, IPropsStore, ILanguage, LanguageSymbol } from '@wix/thunderbolt-symbols'
import { ICaptchaDialog, CaptchaDialogProps } from './types'
import { DIALOG_COMPONENT_ID } from './symbols'
import { enableCyclicTabbing, disableCyclicTabbing } from '@wix/thunderbolt-commons'
import { ISiteScrollBlocker, SiteScrollBlockerSymbol } from 'feature-site-scroll-blocker'

const captchaDialogFactory = (
	structureApi: IStructureAPI,
	propsStore: IPropsStore,
	language: ILanguage,
	siteScrollBlocker: ISiteScrollBlocker
): ICaptchaDialog => {
	const openDialog = (props: CaptchaDialogProps) => {
		enableCyclicTabbing()
		siteScrollBlocker.setSiteScrollingBlocked(true, DIALOG_COMPONENT_ID)
		propsStore.update({ [DIALOG_COMPONENT_ID]: props })
		structureApi.addComponentToDynamicStructure(DIALOG_COMPONENT_ID, {
			componentType: 'CaptchaDialog',
			components: [],
		})
	}

	const hideDialog = () => {
		siteScrollBlocker.setSiteScrollingBlocked(false, DIALOG_COMPONENT_ID)
		disableCyclicTabbing()
		structureApi.removeComponentFromDynamicStructure(DIALOG_COMPONENT_ID)
	}

	return {
		openCaptchaDialog() {
			return new Promise((resolve) => {
				hideDialog()
				openDialog({
					onVerified: (token: string) => {
						hideDialog()
						resolve(token)
					},
					onClose: () => {
						hideDialog()
						resolve(null)
					},
					language: language.userLanguage,
				})
			})
		},
	}
}

export const CaptchaDialog = withDependencies(
	[StructureAPI, Props, LanguageSymbol, SiteScrollBlockerSymbol],
	captchaDialogFactory
)
