import { named, withDependencies } from '@wix/thunderbolt-ioc'
import {
	SiteFeatureConfigSymbol,
	PlatformEnvDataProvider,
	SiteWixCodeSdkSiteConfig,
	ExperimentsSymbol,
	Experiments,
	ViewerModelSym,
	ViewerModel,
} from '@wix/thunderbolt-symbols'
import { ISessionManager, SessionManagerSymbol } from 'feature-session-manager'
import { name } from '../symbols'

export const siteEnvDataProvider = withDependencies(
	[SessionManagerSymbol, ExperimentsSymbol, named(SiteFeatureConfigSymbol, name), ViewerModelSym],
	(
		sessionManager: ISessionManager,
		experiments: Experiments,
		{ pageIdToTitle }: SiteWixCodeSdkSiteConfig,
		viewerModel: ViewerModel
	): PlatformEnvDataProvider => {
		const {
			mode,
			site: { isResponsive },
		} = viewerModel

		return {
			get platformEnvData() {
				return {
					site: {
						applicationsInstances: sessionManager.getAllInstances(),
						experiments,
						isResponsive,
						pageIdToTitle,
						mode,
					},
				}
			},
		}
	}
)
